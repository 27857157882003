import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Footer, GuidanceSection } from '@common/ui/components';

import { BusinessPlansSection } from './BusinessPlansSection';
import { FAQSection } from './FAQSection';
import { PlanFeatureTableSection } from './PlanFeatureTableSection';
import { PlansSection } from './PlansSection';
import { PaymentMethods, PAYMENT_METHODS } from './types';

function Pricing() {
  const [isMobileView, setIsMobileView] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>(PAYMENT_METHODS.ANNUAL);

  const FEATURE_TABLE_ID = 'feature-table';

  useEffect(() => {
    const handleWindowResize = () => {
      const MAX_PAGE_WIDTH = 1024;

      if (window.innerWidth < MAX_PAGE_WIDTH) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.checked
      ? setPaymentMethod(PAYMENT_METHODS.ANNUAL)
      : setPaymentMethod(PAYMENT_METHODS.MONTHLY);
  };

  const handleAnchorClick = () => {
    const featureTableSection = document.querySelector(`#${FEATURE_TABLE_ID}`);

    if (featureTableSection) {
      const positionY = featureTableSection.getBoundingClientRect().top + window.pageYOffset;

      window.scrollTo({ top: positionY });
    }
  };

  return (
    <>
      <PricingPageLayout>
        <Main>
          <PlansSectionBox>
            <CenteredLayout>
              <PlansSection
                paymentMethod={paymentMethod}
                onChangeSwitch={handleSwitchChange}
                onClickAnchor={handleAnchorClick}
              />
            </CenteredLayout>
          </PlansSectionBox>
          <SectionBox>
            <CenteredLayout>
              <BusinessPlansSection paymentMethod={paymentMethod} />
            </CenteredLayout>
          </SectionBox>
          <SectionBox>
            <CenteredLayout>
              <PlanFeatureTableSection isMobileView={isMobileView} />
              <FAQSection />
            </CenteredLayout>
          </SectionBox>
        </Main>
      </PricingPageLayout>
      <GuidanceSection />
      <Footer />
    </>
  );
}

const PricingPageLayout = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.landing_color.grey30};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const Main = styled.main`
  width: 100%;
`;

const PlansSectionBox = styled.section`
  align-items: center;
  background-color: #ecf3ff;
  display: flex;
  flex-direction: column;
`;

const SectionBox = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const CenteredLayout = styled.article`
  box-sizing: border-box;
  max-width: 1440px;
  width: 100%;
`;

export default Pricing;
